<template>
  <div class="g_page_box">
    <g-loading :loading="loading" />
    <div class="g_main_content" v-if="data">
      <div class="detail_status" :class="data.state!==1?'done':''">订单状态：{{data.state===1?'待就诊':data.state===2?'已完成':'已取消'}}</div>
      <div class="top_card_out">
        <div class="top_card">
          <div class="card_title">
            {{ data.department_name }} <br />
            挂号预约单
          </div>
          <div class="card_center">
            <div class="card_round"></div>
            <div class="van-hairline--top-bottom card_line"></div>
            <div class="card_round s2"></div>
          </div>
          <div class="card_bottom">
            <div class="card_info">
              <div class="card_left">就诊科室</div>
              <div class="card_right">
                {{ data.department_name }}
              </div>
            </div>
            <div class="card_info">
              <div class="card_left">就诊医生</div>
              <div class="card_right">
                {{ data.doctor_name }} 
                <!-- <span>[ {{ data.doctor_title }} ]</span> -->
              </div>
            </div>
            <div class="van-hairline--top split_line"></div>
            <div class="card_info">
              <div class="card_left">就诊时间</div>
              <div class="card_right">
                <span
                  >{{ format_date(data.date) }}
                  {{ data.section }}</span
                >
              </div>
            </div>
            <div class="card_info" v-if="data.clinic_title">
              <div class="card_left">门诊类型</div>
              <div class="card_right">
                {{ data.clinic_title }}
              </div>
            </div>
            <div class="card_info">
              <div class="card_left">挂号费用</div>
              <div class="card_right">
                {{ format_money(data.price) }} 元
              </div>
            </div>

            <div class="van-hairline--top split_line"></div>
            <div class="card_info">
              <div class="card_left">就诊人</div>
              <div class="card_right">
                {{ data.patient_name }}
              </div>
            </div>
            <div class="card_info" v-if="data.patient_id">
              <div class="card_left">健康卡</div>
              <div class="card_right">
                {{ data.patient_id }}
              </div>
            </div>
            <div class="card_info">
              <div class="card_left">手机号</div>
              <div class="card_right">
                {{ data.patient_phone }}
              </div>
            </div>
            <div class="card_info">
              <div class="card_left">订单号</div>
              <div class="card_right">
                {{ data.order_no }}
              </div>
            </div>
          </div>
        </div>
        <div class="bottom_tips">温馨提示</div>
        <div class="bottom_content">
          1.预约成功的短信通知会发送至您的就诊人手机，请注意查收；<br>
          2.如您未收到预约成功的通知短信，可联系客服人员咨询详情，客服电话：
          <span>400-123-1234</span>
        </div>
      </div>
      <div class="bottom_btn" @click="before_cancel" v-if="data.state===1">取消预约</div>
    </div>
  </div>
</template>
<script>
import { cancel_info, family_info } from "./service";
import { format_money, format_date, format_medical_card } from "../../utils/format";
import { mapState } from "vuex";
import { Dialog } from 'vant';
export default {
  data() {
    return {
      loading: false,
      family: null,
    };
  },
  computed: {
    ...mapState("hospital", {
      data: (state) => state.order_page,
    }),
  },
  components: {},
  watch: {},
  created() {
    this.get_family()
  },
  destroyed() {},
  methods: {
    format_money,
    format_date,
    format_medical_card,
    before_cancel(){
      Dialog.confirm({
        title: '提示',
        message: '请确认是否取消当前预约？',
        confirmButtonText: '确认',
        cancelButtonText: '再等等',
      })
        .then(() => {
          this.cancel_order()
        })
        .catch(() => {
          // on cancel
        })
    },
    async cancel_order(){
      this.loading = true
      try {
        await cancel_info({order_no: this.data.order_no})
        this.$notify({ type: "success", message: "取消成功" });
        this.$router.go(-1)
      } finally {
        this.loading = false
      }
    },
    async get_family(){
      this.loading = true
      try {
        const {data} = await family_info()
        const family = data.members
        for(let i = 0; i < family.length; i++) {
          const item = family[i]
          if(this.data.patient_name === item.name) {
            this.data.patient_phone = item.phone
            break
          }
        }
      } finally {
        this.loading = false
      }
    },
  },
};
</script>
<style scoped>
.g_main_content {
  background: #f2f7f8;
}

.detail_status {
  height: 36px;
  background: #fff5be;
  font-size: 13px;
  line-height: 36px;
  color: #ffa700;
  text-align: center;

  &.done {
    background: #EBEDF0;
    color: #9C9C9C;
  }
}

.top_card_out {
  padding: 12px 12px 80px 12px;
}

.split_line {
  height: 1px;
  margin: 10px 0;
}

.top_card {
  min-height: 245px;
  background: #ffffff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  border-bottom: dashed 3px #f2f7f8;
}

.card_title {
  padding: 16px 0 2px 0;
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  color: #242938;
  text-align: center;
}

.card_center {
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
}

.card_round {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #f2f7f8;
  position: relative;
  margin-left: -13px;

  &.s2 {
    margin-left: 0;
    margin-right: -13px;
  }
}

.card_line {
  width: calc(100% - 30px);
  height: 4px;
}

.card_left {
  width: 60px;
}

.card_bottom {
  padding: 1px 12px 16px 12px;
}

.card_info {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 32px;
  color: #898995;
}

.card_left {
  margin-right: 24px;
}

.card_right {
  color: #242938;

  & span {
    color: #ffa700;
  }
}

.bottom_tips {
  font-size: 14px;
  line-height: 20px;
  color: #242938;
  padding: 24px 0 12px;
}

.bottom_content {
  font-size: 14px;
  line-height: 20px;
  color: #9C9C9C;

  & span {
    color: #1890FF;
  }
}

.bottom_btn {
  width: calc(100% - 24px);
  position: fixed;
  bottom: 12px;
  margin: 0 12px;
  height: 44px;
  background: #FFFFFF;
  border: 1px solid #1890FF;
  border-radius: 22px;
  font-size: 14px;
  line-height: 44px;
  color: #1890FF;
  text-align: center;
}

</style>
