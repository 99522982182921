import api from '../../utils/api'

// 授权
export async function record_info (payload) {
  console.log(payload)
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        data: {
          record_id: 1,
          price: 23400,
          status: 1,
          doctor_name: '医生234',
          doctor_id: 1,
          doctor_title: '主治医生',
          department: '泌尿外科',
          hospital_name: '罗湖中心医院',
          time_see_doctor: '9:00-10:00',
          clinic_type: 1,
          clinic_title: '专家门诊',
          patient_name: '王麻子',
          patient_id: 1,
          patient_card_no: 938939863833,
          patient_phone: 13540332223,
          order_no: 231903900395,
          appointment_name: '李可心',
          appointmen_id: 123,
          appointmen_phone: 13540332222,
          date_see_doctor: new Date()
        }
      })
    }, 300)
  })
  //return api.get(`/api/v1/mp/register/records/${payload}`)
  //return api.post(`http://yapi.hitales.ai:5000/mock/393/api/v1/mp/orders/estimate`,payload)
}


export async function cancel_info (payload) {
  return api.post(`/api/v1/mp/register/cancel`, payload)
  //return api.post(`http://yapi.hitales.ai:5000/mock/393/api/v1/mp/orders/estimate`,payload)
}

export async function family_info (payload) {
  return api.get(`/api/v1/mp/families/relationships`, payload)
  //return api.get(`http://yapi.hitales.ai:5000/mock/393/api/v1/mp/families/relationships`,payload)
}









